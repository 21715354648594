import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeHexCase } from '../redux/preferenceSlice';
import { setStrage } from '../helpers/strage';
// Components
import Radio from '../components/Radio';

function SettingCanvasHexCase() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const hexCase = useSelector((state) => state.preference.hexCase);
  const colorHex = useSelector((state) => state.colorHex.value);

  const bindChange = (e) => {
    dispatch(changeHexCase({ hexCase: e.target.value, colorHex }));
  };

  setStrage('setting', { hexCase });

  return (
    <div>
      <div className="d-inline-block me-2">
        <Radio
          onChange={bindChange.bind(this)}
          id="settingCanvasHexRadioUpper"
          title={locale.setting_canvas_hex_sec_radio_upper}
          groupName="settingCanvasHexRadio"
          value="upperCase"
          checked={hexCase === 'upperCase' ? true : false}
        />
      </div>

      <Radio
        onChange={bindChange.bind(this)}
        id="settingCanvasHexRadioLower"
        title={locale.setting_canvas_hex_sec_radio_lower}
        groupName="settingCanvasHexRadio"
        value="lowerCase"
        checked={hexCase === 'lowerCase' ? true : false}
      />
    </div>
  );
}

export default SettingCanvasHexCase;
