import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeColorFromFavs } from '../../redux/favColorsSlice';
import { changeHexInput } from '../../redux/colorHexSlice';
import { getStrage, setStrage } from '../../helpers/strage';
import { hexToRgb } from '../../helpers/colorHelpers';
import popupEffect from '../../helpers/popupEffect';
import getAndInsertPopupNodeToBody from '../../helpers/getAndInsertPopupNodeToBody';
import X from '../../components/icons/X';

function FavsItem(props) {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const favColors = useSelector((state) => state.favColors.colors);
  const hexCase = useSelector((state) => state.preference.hexCase);

  const showLoadedPopup = (triggerNode) => {
    popupEffect({
      displayNode: getAndInsertPopupNodeToBody({
        innerText: locale.app_loaded,
      }),
      triggerNode: triggerNode,
      animStepLength: 10,
      animYDistance: -35,
    });
  };

  const handleDeleteBtnClick = () => {
    // [note] お気に入りのstateとstrage両方を準備
    let favsColorsStrage = getStrage('favs').colors
      ? getStrage('favs').colors
      : [];

    // [note] お気に入りから削除 & ストレージから削除
    if (favColors.includes(props.hex)) {
      dispatch(removeColorFromFavs(props.hex));
      favsColorsStrage = favsColorsStrage.filter((color) => {
        return color !== props.hex;
      });
      setStrage('favs', { colors: favsColorsStrage });
      return false;
    }
  };

  const handleLoadBtnClick = (e) => {
    showLoadedPopup(e.target);
    dispatch(changeHexInput({ value: props.hex, hexCase }));
  };

  return (
    <div className="fav-item">
      <button
        onClick={() => {
          handleDeleteBtnClick();
        }}
        className="fav-item__delete-btn"
      >
        <X width="0.8rem" height="0.8rem" />
      </button>
      <div
        className="fav-item__tip"
        style={{
          backgroundColor: `#${props.hex}`,
        }}
      ></div>
      <div className="fav-item__detail">
        <div>#{props.hex}</div>
        <div>
          R: {hexToRgb(props.hex)[0]} G: {hexToRgb(props.hex)[1]} B:{' '}
          {hexToRgb(props.hex)[2]}
        </div>
        <div className="mt-1">
          <button
            onClick={(e) => {
              handleLoadBtnClick(e);
            }}
            className="fav-item__load-btn"
          >
            {locale.app_load}
          </button>
        </div>
      </div>
    </div>
  );
}

export default FavsItem;
