import { createSlice, createAction } from '@reduxjs/toolkit';
import { getStrage } from '../helpers/strage';
import {
  rgbToHex,
  isContainNonHexChar,
  isRgbMember,
  getRandomHex,
} from '../helpers/colorHelpers';

// extra action
const colorR_changeRInput = createAction(
  'colorR/changeRInput',
  function prepare(text) {}
);
const colorG_changeGInput = createAction(
  'colorG/changeGInput',
  function prepare(text) {}
);
const colorB_changeBInput = createAction(
  'colorB/changeBInput',
  function prepare(text) {}
);
const preference_changeHexCase = createAction(
  'preference/changeHexCase',
  function prepare(text) {}
);

const storage = getStrage('setting');
const initialState = storage.hasOwnProperty('colorHex')
  ? { value: storage.colorHex }
  : { value: getRandomHex() };

// helper
function convertHexCase(v, willCase) {
  if (willCase === 'upperCase') return v.toUpperCase();
  if (willCase === 'lowerCase') return v.toLowerCase();
  return null;
}

export const colorHexSlice = createSlice({
  name: 'colorHex',
  initialState,
  reducers: {
    changeHexInput: (state, action) => {
      let v = action.payload.value;
      let crHexCase = action.payload.hexCase;
      v = convertHexCase(v, crHexCase);
      // 1文字目が#であるケースの処理
      if (v.charAt(0) === '#') v = v.slice(1);
      v = isContainNonHexChar(v) || String(v).length >= 7 ? state.value : v;
      state.value = v;
    },
    initHexInput: (state, action) => {
      let v = action.payload;
      v = v.toUpperCase();
      state.value = v;
    },
  },
  extraReducers: {
    [colorR_changeRInput]: (state, action) => {
      let rtnV;
      let inputRValue = action.payload.value;
      let storeRGBValues = action.payload.rgb;
      let storeHexCase = action.payload.hexCase;
      if (isRgbMember(inputRValue)) {
        rtnV = rgbToHex([
          Number(inputRValue),
          Number(storeRGBValues.g),
          Number(storeRGBValues.b),
        ]);
        rtnV = convertHexCase(rtnV, storeHexCase);
        state.value = rtnV;
      }
    },
    [colorG_changeGInput]: (state, action) => {
      let rtnV;
      let inputGValue = action.payload.value;
      let storeRGBValues = action.payload.rgb;
      let storeHexCase = action.payload.hexCase;
      if (isRgbMember(inputGValue)) {
        rtnV = rgbToHex([
          Number(storeRGBValues.r),
          Number(inputGValue),
          Number(storeRGBValues.b),
        ]);
        rtnV = convertHexCase(rtnV, storeHexCase);
        state.value = rtnV;
      }
    },
    [colorB_changeBInput]: (state, action) => {
      let rtnV;
      let inputBValue = action.payload.value;
      let storeRGBValues = action.payload.rgb;
      let storeHexCase = action.payload.hexCase;
      if (isRgbMember(inputBValue)) {
        rtnV = rgbToHex([
          Number(storeRGBValues.r),
          Number(storeRGBValues.g),
          Number(inputBValue),
        ]);
        rtnV = convertHexCase(rtnV, storeHexCase);
        state.value = rtnV;
      }
    },
    [preference_changeHexCase]: (state, action) => {
      let hexValue = action.payload.colorHex;
      let hexCase = action.payload.hexCase;
      hexValue = convertHexCase(hexValue, hexCase);
      state.value = hexValue;
    },
  },
});

export const { changeHexInput, initHexInput } = colorHexSlice.actions;

export default colorHexSlice.reducer;
