import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleSettingCanvas, showBackdrop } from '../../redux/layoutsSlice';
import GearFill from '../../components/icons/GearFill';

function SettingCanvasOpener() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleSettingCanvas());
    dispatch(showBackdrop());
  };

  return (
    <div
      className="setting-opener"
      onClick={() => {
        handleClick();
      }}
    >
      <GearFill width="1.5rem" height="1.5rem" />
    </div>
  );
}

export default SettingCanvasOpener;
