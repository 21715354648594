import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeHexInput } from '../../redux/colorHexSlice';
import { isHex } from '../../helpers/colorHelpers';
import { setStrage } from '../../helpers/strage';

function InputHex() {
  const dispatch = useDispatch();
  const colorHex = useSelector((state) => state.colorHex.value);
  const colorR = useSelector((state) => state.colorR.value);
  const colorG = useSelector((state) => state.colorG.value);
  const colorB = useSelector((state) => state.colorB.value);
  const hexCase = useSelector((state) => state.preference.hexCase);

  const isValidColors = () => {
    return isHex(colorHex) && colorR !== '' && colorG !== '' && colorB !== '';
  };

  const handleInputChange = (e, hexCase) => {
    dispatch(changeHexInput({ value: e.target.value, hexCase }));
  };

  setStrage('setting', { colorHex });

  return (
    <input
      onChange={(e) => {
        handleInputChange(e, hexCase);
      }}
      value={colorHex}
      maxLength="7"
      className={`input-hex ${
        isValidColors() ? '' : 'input-hex--state_wrong'
      } ${useSelector((state) => state.colorHex.value)}`}
    />
  );
}

export default InputHex;
