import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeScaleNumInput } from '../../redux/scaleNumSlice';
import { setStrage } from '../../helpers/strage';

function InputScaleNum() {
  const dispatch = useDispatch();
  const scaleNum = useSelector((state) => state.scaleNum.value);

  setStrage('setting', { scaleNum });
  return (
    <div>
      <input
        className={`input-scale-num ${
          Number(scaleNum) <= 1 ? 'text-disabled' : ''
        }`}
        type="number"
        onChange={(e) => {
          dispatch(changeScaleNumInput({ value: e.target.value }));
        }}
        value={scaleNum}
      />
    </div>
  );
}

// const mapStateToProps = (state) => ({
//   scaleNum: state.scaleNum.value,
// });

// const mapDispatchToProps = (dispatch) => ({
//   changeScaleNumInput: (e) => dispatch(changeScaleNumInput(e)),
// });

export default InputScaleNum;
