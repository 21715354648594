import React from 'react';
import { useSelector } from 'react-redux';
// Named Components
import ScaleTips from '../components_named/ScalesSection/ScaleTips';
import ScaleTipUtilities from '../components_named/ScalesSection/ScaleTipUtilities';
import IconBtnBsScaleModal from '../components_named/ScalesSection/IconBtnBsScaleModal';

function ScalesSection() {
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];

  const scaleInfo = [
    {
      id: 'white',
      name: locale.scales_section_white_title,
    },
    // {
    //   id: 'white_not_include_end',
    //   name: locale.scales_section_white_not_include_end_title,
    //   subName: locale.scales_section_white_not_include_end_note,
    // },
    {
      id: 'black',
      name: locale.scales_section_black_title,
    },
    // {
    //   id: 'black_not_include_end',
    //   name: locale.scales_section_black_not_include_end_title,
    //   subName: locale.scales_section_black_not_include_end_note,
    // },
    {
      id: 'red',
      name: locale.scales_section_red_title,
    },
    {
      id: 'green',
      name: locale.scales_section_green_title,
    },
    {
      id: 'blue',
      name: locale.scales_section_blue_title,
    },
    {
      id: 'complementary',
      name: locale.scales_section_complementary_title,
    },
    {
      id: 'hsb_h',
      name: locale.scales_section_hsb_h_title,
    },
    {
      id: 'bootstrap5',
      name: locale.scales_section_bootstrap5_title,
    },
  ];

  return (
    <div className="scale-items">
      {scaleInfo.map((scale) => {
        return (
          <div key={scale.id} className="scale-item mb-3">
            <div className="scale-item__ttl">
              {scale.name}
              {scale.id === 'bootstrap5' && (
                <span className="ms-2">
                  <IconBtnBsScaleModal />
                </span>
              )}
              <span className="scale-item__ttl-note">{scale.subName}</span>
            </div>
            <div className="scale-item__utilities-wrap mb-1">
              <ScaleTipUtilities key={scale.id} scaleType={scale.id} />
            </div>
            <div className="scale-item__tips-wrap">
              <ScaleTips key={scale.id} scaleType={scale.id} />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ScalesSection;
