import React from 'react';
import { useSelector } from 'react-redux';
import { isHex } from '../../helpers/colorHelpers';
import getAndInsertPopupNodeToBody from '../../helpers/getAndInsertPopupNodeToBody';
import popupEffect from '../../helpers/popupEffect';
import copyToClipboard from '../../helpers/copyToClipboard';
import ScaleTipBsDeviationInput from './ScaleTipBsDeviationInput';

function ScaleTip(props) {
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  // const scaleNum = useSelector((state) => state.scaleNum.value);
  const colorHex = useSelector((state) => state.colorHex.value);
  const colorR = useSelector((state) => state.colorR.value);
  const colorG = useSelector((state) => state.colorG.value);
  const colorB = useSelector((state) => state.colorB.value);
  const hexCase = useSelector((state) => state.preference.hexCase);
  const activeCkIndex = useSelector(
    (state) => state.colorTipContents.activeCkIndex
  );
  const activeCkHex = useSelector(
    (state) => state.colorTipContents.activeCkHex
  );
  const activeCkRgb = useSelector(
    (state) => state.colorTipContents.activeCkRgb
  );
  const scaleTipSize = useSelector((state) => state.scaleTipSize.value);

  let hexValue = props.hex;
  if (hexCase === 'upperCase') hexValue = hexValue.toUpperCase();
  if (hexCase === 'lowerCase') hexValue = hexValue.toLowerCase();

  const isBootstrapTip = () => {
    return props.scaleType === 'bootstrap5';
  };

  const isBoostsrapBaseIndex = (val) => {
    return Number(val) === 500;
  };

  const isValidColors = () => {
    return isHex(colorHex) && colorR !== '' && colorG !== '' && colorB !== '';
  };

  const showCopiedPopup = (triggerNode) => {
    popupEffect({
      displayNode: getAndInsertPopupNodeToBody({
        innerText: locale.app_copied,
      }),
      triggerNode: triggerNode,
      animStepLength: 10,
      animYDistance: -40,
    });
  };

  const handleHexCopyClick = (e) => {
    let text = `#${props.hex}`;
    if (hexCase === 'upperCase') text = text.toUpperCase();
    if (hexCase === 'lowerCase') text = text.toLowerCase();
    copyToClipboard(text);
    showCopiedPopup(e.target);
  };

  const handleRgbCopyClick = (e) => {
    const text = `rgb(${props.r}, ${props.g}, ${props.b})`;
    showCopiedPopup(e.target);
    copyToClipboard(text);
  };

  const colorValue = isValidColors()
    ? `rgb(${props.r}, ${props.g}, ${props.b})`
    : '#eee';

  return (
    <div>
      <div className={`scale-tip-wrap scale-tip-wrap--size_${scaleTipSize}`}>
        <div className={`scale-tip scale-tip--size_${scaleTipSize}`}>
          {activeCkIndex === 'on' && (
            <div className="scale-tip__index">{props.index}</div>
          )}

          <div
            className="scale-tip__color"
            style={{
              backgroundColor: colorValue,
            }}
          ></div>

          {activeCkHex === 'on' && isValidColors() && (
            <div
              onClick={(e) => {
                handleHexCopyClick(e);
              }}
              className="scale-tip__color-value"
            >
              #{hexValue}
            </div>
          )}
          {activeCkHex === 'on' && !isValidColors() && (
            <div className="scale-tip__color-value text-disabled">
              {locale.scales_section_tip_color_invalid_txt}
            </div>
          )}

          {activeCkRgb === 'on' && isValidColors() && (
            <div
              onClick={(e) => {
                handleRgbCopyClick(e);
              }}
              className="scale-tip__color-value"
            >
              {props.r},{props.g},{props.b}
            </div>
          )}
          {activeCkRgb === 'on' && !isValidColors() && (
            <div className="scale-tip__color-value text-disabled">
              {locale.scales_section_tip_color_invalid_txt}
            </div>
          )}
        </div>
        {isBootstrapTip() && !isBoostsrapBaseIndex(props.index) && (
          <div className="pt-2 text-center">
            <ScaleTipBsDeviationInput index={props.index} />%
          </div>
        )}
      </div>
    </div>
  );
}

export default ScaleTip;
