// import Save2 from '../../components/icons/Save2';

function ScaleUtilLink(props) {
  return (
    <button
      onClick={props.onClick}
      className={`scale-util-link me-2 ${props.additionalClass}`}
    >
      {/* <Save2 width="0.6rem" height="0.6rem" /> */}
      <span className="d-inline-block">{props.title}</span>
    </button>
  );
}

export default ScaleUtilLink;
