import XCiercle from '../../components/icons/XCiercle';

function CloseBtn(props) {
  return (
    <button className="app-modal-close" onClick={props.onClick}>
      <XCiercle width="1.5rem" height="1.5rem" />
    </button>
  );
}

export default CloseBtn;
