import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initFavs } from '../../redux/favColorsSlice';
import { removeStrage } from '../../helpers/strage';
import popupEffect from '../../helpers/popupEffect';
import getAndInsertPopupNodeToBody from '../../helpers/getAndInsertPopupNodeToBody';
import Btn from '../../components/Btn';

function ResetFavsBtn() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];

  const showResetPopup = (triggerNode) => {
    popupEffect({
      displayNode: getAndInsertPopupNodeToBody({
        innerText: locale.app_reset,
      }),
      triggerNode: triggerNode,
      animStepLength: 10,
      animYDistance: -45,
    });
  };

  const handleClick = (e) => {
    removeStrage('favs');
    dispatch(initFavs());
    showResetPopup(e.target);
  };

  return (
    <Btn
      onClick={handleClick.bind(this)}
      listIcon="Trash"
      title={locale.setting_canvas_reset_sec_btn_reset_color}
    />
  );
}

export default ResetFavsBtn;
