import { useSelector } from 'react-redux';
import FavsItem from './FavsItem';

function FavsModalContent() {
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const favColors = useSelector((state) => state.favColors.colors);

  return (
    <div>
      <h4 className="app-modal-ttl">{locale.favs_modal_title}</h4>
      <div className="fav-items">
        {favColors.map((color) => {
          return <FavsItem key={color} hex={color} />;
        })}
      </div>
    </div>
  );
}

export default FavsModalContent;
