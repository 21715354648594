const SETTING_STRAGE_NAME = 'color_scale_app_setting';
const FAVS_STRAGE_NAME = 'color_scale_app_fav_colors';

// [note] strageId => setting or favs

export const getStrageName = (strageId) => {
  if (strageId === 'setting') return SETTING_STRAGE_NAME;
  if (strageId === 'favs') return FAVS_STRAGE_NAME;
  if (strageId !== 'setting' || strageId !== 'favs') return false;
};

export const setStrage = (strageId, member) => {
  const strage = getStrage(strageId) ? getStrage(strageId) : {};
  let context = getStrageName(strageId);
  if (!member) return false;
  window.localStorage.setItem(
    context,
    JSON.stringify({ ...strage, ...member })
  );
};

export const getStrage = (strageId) => {
  const context = getStrageName(strageId);
  return JSON.parse(window.localStorage.getItem(context)) || {};
};

export const removeStrage = (strageId) => {
  const context = getStrageName(strageId);
  window.localStorage.removeItem(context);
};
