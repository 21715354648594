import React from 'react';

function Radio(props) {
  return (
    <label htmlFor={props.id} className="app-radio">
      <input
        onChange={props.onChange}
        type="radio"
        className="app-radio__input"
        id={props.id}
        name={props.groupName}
        value={props.value}
        checked={props.checked}
      />
      <span className="app-radio__name">{props.title}</span>
    </label>
  );
}

export default Radio;
