function SectionSubTitle(props) {
  return (
    <h3 className="app-section-sub-ttl">
      {props.title}
      {props.note && (
        <span className="app-section-sub-ttl__note">{props.note}</span>
      )}
    </h3>
  );
}

export default SectionSubTitle;
