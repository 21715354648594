import React from 'react';

function Logo() {
  return (
    <React.Fragment>
      <h1 className="app-header-logo text-uppercase">Color Scale WEB</h1>
      <p className="app-header-lead">Generate and get gradual colors!</p>
      <a
        className="app-header-site-link"
        href="https://masa-sumimoto.com"
        rel="noreferrer"
        target="_blank"
      >
        by masa-sumimoto
      </a>
    </React.Fragment>
  );
}

export default Logo;
