import { createSlice } from '@reduxjs/toolkit';
import { getStrage } from '../helpers/strage';
const storage = getStrage('setting');
const defaultState = { value: 'm' };
const initialState = storage.hasOwnProperty('scaleTipSize')
  ? { value: storage.scaleTipSize }
  : defaultState;

export const scaleTipSizeSlice = createSlice({
  name: 'scaleNum',
  initialState,
  reducers: {
    changeScaleTipSize: (state, action) => {
      if (action.payload.value === 's') state.value = 's';
      if (action.payload.value === 'm') state.value = 'm';
      if (action.payload.value === 'l') state.value = 'l';
      return state;
    },
    initScaleTipSize: (state) => {
      state.value = defaultState.value;
    },
  },
});

export const { changeScaleTipSize, initScaleTipSize } =
  scaleTipSizeSlice.actions;

export default scaleTipSizeSlice.reducer;
