import React from 'react';

function Footer() {
  return (
    <footer className="app-footer">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-md-6 mb-2">
            <p className="app-footer-logo">Color Scale Web</p>
            <small className="app-footer-copyright">
              © 2021 Masaaki Sumimoto. All rights reserved.
            </small>
          </div>
          <div className="col-12 col-md-6 mb-2">
            <div className="">
              <a
                className="app-footer-xd-plugin-link mr-0"
                href="https://adobe.com/go/cc_plugins_discover_plugin?pluginId=d77f842c&workflow=share"
                rel="noreferrer"
                target="_blank"
              >
                Get Adobe XD Plugin
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
