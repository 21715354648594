import ThemeSelector from './ThemeSelector';

function ThemeSelectors() {
  const selectors = ['a', 'b', 'c', 'd'];
  return (
    <div className="theme-selectors">
      {selectors.map((selector) => (
        <ThemeSelector key={selector} id={selector} />
      ))}
    </div>
  );
}

export default ThemeSelectors;
