import { createSlice } from '@reduxjs/toolkit';
import { getStrage } from '../helpers/strage';

const storage = getStrage('favs');
const defaultState = { colors: [] };
const initialState = storage.hasOwnProperty('colors')
  ? { colors: storage.colors }
  : defaultState;

export const favColorsSlice = createSlice({
  name: 'favColors',
  initialState,
  reducers: {
    addColorToFavs: (state, action) => {
      let arr = [...state.colors, ...[action.payload]];
      arr = arr.filter((x, i, self) => {
        return self.indexOf(x) === i;
      });
      state.colors = arr;
    },
    removeColorFromFavs: (state, action) => {
      // 既存のお気に入りから対象カラーのみ削除
      const result = state.colors.filter((color) => {
        return color !== action.payload;
      });
      state.colors = result;
    },
    initFavs: (state, action) => {
      state.colors = defaultState.colors;
    },
  },
});

export const { addColorToFavs, removeColorFromFavs, initFavs } =
  favColorsSlice.actions;

export default favColorsSlice.reducer;
