import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isShownBackdrop: false,
  isShownModalBackdrop: false,
  isShownModal: false,
  isShownSettingCanvas: false,
};

export const layoutsSlice = createSlice({
  name: 'layouts',
  initialState,
  reducers: {
    showBackdrop: (state, action) => {
      state.isShownBackdrop = true;
    },
    hideBackdrop: (state, action) => {
      state.isShownBackdrop = false;
    },
    showModalBackdrop: (state, action) => {
      state.isShownModalBackdrop = true;
    },
    hideModalBackdrop: (state, action) => {
      state.isShownModalBackdrop = false;
    },
    showModal: (state, action) => {
      state.isShownModal = true;
    },
    hideModal: (state, action) => {
      state.isShownModal = false;
    },
    showSettingCanvas: (state, action) => {
      state.isShownSettingCanvas = true;
    },
    hideSettingCanvas: (state, action) => {
      state.isShownSettingCanvas = false;
    },
    toggleSettingCanvas: (state, action) => {
      state.isShownSettingCanvas = !state.isShownSettingCanvas;
    },
  },
});

export const {
  showBackdrop,
  hideBackdrop,
  showModalBackdrop,
  hideModalBackdrop,
  showModal,
  hideModal,
  showSettingCanvas,
  hideSettingCanvas,
  toggleSettingCanvas,
} = layoutsSlice.actions;

export default layoutsSlice.reducer;
