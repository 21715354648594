import Star from './icons/Star';
import StarFill from './icons/StarFill';
import Folder from './icons/Folder';
import Trash from './icons/Trash';
import Question from './icons/Question';

function Btn(props) {
  return (
    <button
      onClick={props.onClick}
      className={`app-btn ${props.listIcon && 'app-btn--type_list-icon'} ${
        props.colorType ? `app-btn--color_${props.colorType}` : ''
      }
        `}
    >
      {props.listIcon && (
        <span className="app-btn__list-icon">
          {props.listIcon === 'Star' && <Star width="14" height="14" />}
          {props.listIcon === 'StarFill' && <StarFill width="14" height="14" />}
          {props.listIcon === 'Folder' && <Folder width="14" height="14" />}
          {props.listIcon === 'Trash' && <Trash width="14" height="14" />}
          {props.listIcon === 'QuestionOctagonFill' && (
            <Question width="14" height="14" />
          )}
        </span>
      )}
      <span className="app-btn__txt">{props.title}</span>
    </button>
  );
}

export default Btn;
