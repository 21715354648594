import { configureStore } from '@reduxjs/toolkit';
import preferenceSlice from './preferenceSlice';
import wordsSlice from './wordsSlice';
import colorHexSlice from './colorHexSlice';
import colorRSlice from './colorRSlice';
import colorGSlice from './colorGSlice';
import colorBSlice from './colorBSlice';
import favColorsSlice from './favColorsSlice';
import modalContentSlice from './modalContentSlice';
import layoutsSlice from './layoutsSlice';
import colorTipContentsSlice from './colorTipContentsSlice';
import scaleNumSlice from './scaleNumSlice';
import scaleTipSizeSlice from './scaleTipSizeSlice';
import bsDeviationPercentsSlice from './bsDeviationPercentsSlice';

export const store = configureStore({
  reducer: {
    preference: preferenceSlice,
    words: wordsSlice,
    colorHex: colorHexSlice,
    colorR: colorRSlice,
    colorG: colorGSlice,
    colorB: colorBSlice,
    favColors: favColorsSlice,
    modalContent: modalContentSlice,
    layouts: layoutsSlice,
    colorTipContents: colorTipContentsSlice,
    scaleNum: scaleNumSlice,
    scaleTipSize: scaleTipSizeSlice,
    bsDeviationPercents: bsDeviationPercentsSlice,
  },
});
