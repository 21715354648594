import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeBInput } from '../../redux/colorBSlice';
import { isHex } from '../../helpers/colorHelpers';
import { setStrage } from '../../helpers/strage';

function InputB() {
  const dispatch = useDispatch();
  const colorHex = useSelector((state) => state.colorHex.value);
  const colorR = useSelector((state) => state.colorR.value);
  const colorG = useSelector((state) => state.colorG.value);
  const colorB = useSelector((state) => state.colorB.value);
  const hexCase = useSelector((state) => state.preference.hexCase);

  const isValidColors = () => {
    return isHex(colorHex) && colorR !== '' && colorG !== '' && colorB !== '';
  };

  setStrage('setting', { colorB });

  return (
    <input
      type="text"
      onChange={(e) => {
        dispatch(
          changeBInput({
            value: e.target.value,
            rgb: {
              r: colorR,
              g: colorG,
              b: colorB,
            },
            hexCase,
          })
        );
      }}
      value={colorB}
      className={`input-rgb ${isValidColors() ? '' : 'input-rgb--state_wrong'}`}
    />
  );
}

export default InputB;
