import React from 'react';
import { useSelector } from 'react-redux';
import { rgbToHex } from '../../helpers/colorHelpers';
import { getColorTipInfoFromColorScale } from '../../helpers/colorScaleHelpers';
import ScaleTip from './ScaleTip';

function ScaleTips(props) {
  const scaleNum = useSelector((state) => state.scaleNum.value);
  // const colorHex = useSelector((state) => state.colorHex.value);
  const colorR = useSelector((state) => state.colorR.value);
  const colorG = useSelector((state) => state.colorG.value);
  const colorB = useSelector((state) => state.colorB.value);
  const deviationPercents = useSelector((state) => state.bsDeviationPercents);
  const scaleType = props.scaleType;
  const scaleLength = scaleType === 'bootstrap5' ? 9 : scaleNum;
  const indexRatio = scaleType === 'bootstrap5' ? 100 : 1;
  const scaleTipArr = [];

  // scale tipコンポーネントの生成
  if (scaleLength > 1) {
    for (let i = 0; i < scaleLength; i++) {
      // bootstrapのみの乖離率の値
      const bsDeviation =
        scaleType === 'bootstrap5'
          ? deviationPercents[(i + 1) * indexRatio]
          : false;

      const info = getColorTipInfoFromColorScale({
        scaleType: scaleType,
        scaleLength: scaleLength,
        index: i,
        r: colorR,
        g: colorG,
        b: colorB,
        bsDeviation: bsDeviation,
      });

      scaleTipArr.push(
        <ScaleTip
          key={i}
          index={(i + 1) * indexRatio}
          r={info.r}
          g={info.g}
          b={info.b}
          hex={String(rgbToHex([info.r, info.g, info.b]))}
          scaleType={scaleType}
        />
      );
    }
  }

  return <div className="scale-tips">{scaleTipArr}</div>;
}

export default ScaleTips;
