import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeRInput } from '../../redux/colorRSlice';
import { isHex } from '../../helpers/colorHelpers';
import { setStrage } from '../../helpers/strage';

function InputR() {
  const dispatch = useDispatch();
  const colorHex = useSelector((state) => state.colorHex.value);
  const colorR = useSelector((state) => state.colorR.value);
  const colorG = useSelector((state) => state.colorG.value);
  const colorB = useSelector((state) => state.colorB.value);
  const hexCase = useSelector((state) => state.preference.hexCase);

  const isValidColors = () => {
    return isHex(colorHex) && colorR !== '' && colorG !== '' && colorB !== '';
  };

  setStrage('setting', { colorR });

  return (
    <input
      type="text"
      onChange={(e) => {
        dispatch(
          changeRInput({
            value: e.target.value,
            rgb: {
              r: colorR,
              g: colorG,
              b: colorB,
            },
            hexCase,
          })
        );
      }}
      value={colorR}
      className={`input-rgb ${isValidColors() ? '' : 'input-rgb--state_wrong'}`}
    />
  );
}

// const mapStateToProps = (state) => ({
//   colorHex: state.colorHex.value,
//   colorR: state.colorR.value,
//   colorG: state.colorG.value,
//   colorB: state.colorB.value,
//   hexCase: state.preference.hexCase,
// });

// const mapDispatchToProps = (dispatch) => ({
//   changeRInput: (e, rgbHash, hexCase) =>
//     dispatch(changeRInput(e, rgbHash, hexCase)),
// });

export default InputR;
