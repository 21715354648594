import React from 'react';
import { useSelector } from 'react-redux';
// Components
import Closer from '../components_named/SettingCanvas/Closer';
import ResetSettingBtn from '../components_named/SettingCanvas/ResetSettingBtn';
import ResetFavsBtn from '../components_named/SettingCanvas/ResetFavsBtn';
import ThemeSelectors from '../components/ThemeSelectors';
// Layout Components
import SettingCanvasState from './SettingCanvasState';
import SettingCanvasLanguage from './SettingCanvasLanguage';
import SettingCanvasHexCase from './SettingCanvasHexCase';

function SettingCanvas() {
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const isShownSettingCanvas = useSelector(
    (state) => state.layouts.isShownSettingCanvas
  );

  return (
    <div className={`setting-canvas ${isShownSettingCanvas ? 'show' : ''}`}>
      <div className="setting-canvas-head">
        <Closer />
        <div className="setting-canvas-ttl text-uppercase">
          {locale.setting_canvas_title}
        </div>
      </div>

      <div className="setting-canvas-body">
        <div className="setting-canvas-section setting-canvas-section--type_state">
          <SettingCanvasState />
        </div>

        <div className="setting-canvas-section">
          <div className="setting-canvas-sub-ttl">
            {locale.setting_canvas_color_theme_sec_title}
          </div>
          <div>
            <ThemeSelectors />
          </div>
        </div>

        <div className="setting-canvas-section">
          <div className="setting-canvas-sub-ttl">
            {locale.setting_canvas_lang_sec_title}
          </div>
          <SettingCanvasLanguage />
        </div>

        <div className="setting-canvas-section">
          <div className="setting-canvas-sub-ttl">
            {locale.setting_canvas_hex_sec_title}
          </div>
          <SettingCanvasHexCase />
        </div>

        <div className="setting-canvas-section">
          <div className="setting-canvas-sub-ttl">
            {locale.setting_canvas_reset_sec_title}
          </div>
          <div>
            <div className="mb-2">
              <ResetSettingBtn />
            </div>
            <div>
              <ResetFavsBtn />
            </div>
          </div>
        </div>

        <div className="setting-canvas-section setting-canvas-section--type_info">
          <div>{locale.setting_canvas_info_sec_app_version}: 1.0.6</div>
        </div>
      </div>
    </div>
  );
}

export default SettingCanvas;
