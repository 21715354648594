import { useSelector } from 'react-redux';

function BsScaleModalContent(props) {
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];

  return (
    <div>
      <h4 className="app-modal-ttl">{locale.bs_modal_sec_1_title}</h4>
      <p className="mb-4">{locale.bs_modal_sec_1_text_1}</p>
      <h4 className="app-modal-ttl">{locale.bs_modal_sec_2_title}</h4>
      <p>{locale.bs_modal_sec_2_text_1}</p>
      <ul className="app-modal-list">
        <li className="app-modal-list__item">{locale.bs_modal_sec_2_list_1}</li>
        <li className="app-modal-list__item">{locale.bs_modal_sec_2_list_2}</li>
        <li className="app-modal-list__item">{locale.bs_modal_sec_2_list_3}</li>
        <li className="app-modal-list__item">{locale.bs_modal_sec_2_list_4}</li>
      </ul>
    </div>
  );
}

export default BsScaleModalContent;
