import React from 'react';
import Logo from '../components_named/Header/Logo';
import HelpModalOpener from '../components_named/Header/HelpModalOpener';
import SettingCanvasOpener from '../components_named/Header/SettingCanvasOpener';
import ThemeSelectors from '../components/ThemeSelectors';

function Header() {
  return (
    <header className="app-header">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-auto">
            <Logo />
          </div>
          <div className="col-auto">
            <div className="d-flex align-items-center">
              <div className="me-2 d-none d-md-block">
                <ThemeSelectors />
              </div>
              <div className="me-2">
                <HelpModalOpener />
              </div>
              <div>
                <SettingCanvasOpener />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
