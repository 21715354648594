function DisabledGrid() {
  return (
    <div className="disabled-grid-wrap">
      <span className="disabled-grid disabled-grid--index_1"></span>
      <span className="disabled-grid disabled-grid--index_2"></span>
      <span className="disabled-grid disabled-grid--index_3"></span>
      <span className="disabled-grid disabled-grid--index_4"></span>
      <span className="disabled-grid disabled-grid--index_5"></span>
      <span className="disabled-grid disabled-grid--index_6"></span>
      <span className="disabled-grid disabled-grid--index_7"></span>
      <span className="disabled-grid disabled-grid--index_8"></span>
    </div>
  );
}

export default DisabledGrid;
