import { createSlice } from '@reduxjs/toolkit';
import { getStrage } from '../helpers/strage';

const storage = getStrage('setting');
const defaultState = {
  activeCkIndex: 'on',
  activeCkHex: 'on',
  activeCkRgb: 'on',
};
const initialState = storage.hasOwnProperty('colorTipContents')
  ? storage.colorTipContents
  : defaultState;

export const colorTipContentsSlice = createSlice({
  name: 'scaleNum',
  initialState,
  reducers: {
    changeColorTipContentsDisplayState: (state, action) => {
      const vid = action.payload.vid;
      const activeCk = action.payload.activeCk;
      if (vid === 'index') {
        state.activeCkIndex = activeCk === 'on' ? 'off' : 'on';
      }
      if (vid === 'hex') {
        state.activeCkHex = activeCk === 'on' ? 'off' : 'on';
      }
      if (vid === 'rgb') {
        state.activeCkRgb = activeCk === 'on' ? 'off' : 'on';
      }
    },
    initColorTipContents: (state) => {
      state.activeCkIndex = defaultState.activeCkIndex;
      state.activeCkHex = defaultState.activeCkHex;
      state.activeCkRgb = defaultState.activeCkRgb;
    },
  },
});

export const { changeColorTipContentsDisplayState, initColorTipContents } =
  colorTipContentsSlice.actions;

export default colorTipContentsSlice.reducer;
