import { useSelector } from 'react-redux';

function HelpModalContent() {
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];

  return (
    <div>
      <h4 className="app-modal-ttl">{locale.help_modal_sec_1_title}</h4>
      <p>{locale.help_modal_sec_1_text_1}</p>
      <p className="mb-2">{locale.help_modal_sec_1_text_2}</p>
      <p className="app-modal-attention mb-4">
        {locale.help_modal_sec_1_text_2_note}
      </p>
      <h4 className="app-modal-ttl">{locale.help_modal_sec_2_title}</h4>
      <ul className="app-modal-list">
        <li className="app-modal-list__item">
          {locale.help_modal_sec_2_list_1}
        </li>
        <li className="app-modal-list__item">
          {locale.help_modal_sec_2_list_2}
        </li>
        <li className="app-modal-list__item">
          {locale.help_modal_sec_2_list_3}
        </li>
      </ul>
    </div>
  );
}

export default HelpModalContent;
