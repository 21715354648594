import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: 'help' };

export const modalContentSlice = createSlice({
  name: 'modalContent',
  initialState,
  reducers: {
    changeModalContent: (state, action) => {
      state.value = action.payload.value;
    },
  },
});

export const { changeModalContent } = modalContentSlice.actions;

export default modalContentSlice.reducer;
