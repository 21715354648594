import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeModalContent } from '../../redux/modalContentSlice';
import { showBackdrop, showModal } from '../../redux/layoutsSlice';
import Btn from '../../components/Btn';

function BtnOpenFavsModal() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];

  const bindClick = () => {
    dispatch(changeModalContent({ value: 'favs' }));
    dispatch(showBackdrop());
    dispatch(showModal());
  };

  return (
    <Btn
      onClick={bindClick.bind(this)}
      listIcon="Folder"
      colorType="fav"
      title={locale.base_section_open_fav_stocker_btn}
    />
  );
}

export default BtnOpenFavsModal;
