import React from 'react';

function Checkbox(props) {
  return (
    <label className="app-checkbox">
      <input
        onChange={props.onChange}
        className="app-checkbox__input"
        type="checkbox"
        checked={props.checked === 'on' ? true : false}
        data-vid={props.vid}
        data-active-ck={props.checked === 'on' ? 'on' : 'off'}
      />
      <span className="app-checkbox__title">{props.title}</span>
    </label>
  );
}

export default Checkbox;
