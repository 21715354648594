import React from 'react';
import { useSelector } from 'react-redux';
import { isHex } from '../../helpers/colorHelpers';
import ColorTipStarIcon from './ColorTipStarIcon';
import DisabledGrid from '../../components/DisabledGrid';

function ColorTip() {
  const colorHex = useSelector((state) => state.colorHex.value);
  const colorR = useSelector((state) => state.colorR.value);
  const colorG = useSelector((state) => state.colorG.value);
  const colorB = useSelector((state) => state.colorB.value);

  const isValidColors = () => {
    return isHex(colorHex) && colorR !== '' && colorG !== '' && colorB !== '';
  };

  return (
    <div
      className="color-tip"
      style={{
        backgroundColor: `#${isHex(colorHex) ? colorHex : 'inherit'}`,
      }}
    >
      {isValidColors() ? '' : <DisabledGrid />}
      {isValidColors() ? <ColorTipStarIcon /> : ''}
    </div>
  );
}

export default ColorTip;
