import { rgbToHsb, hsbToRgb } from './colorHelpers';

export const getToWhiteColorMember = (opts) => {
  if (isNaN(opts.r + opts.g + opts.b)) return false;
  const max = 255;
  const r = opts.r + ((max - opts.r) / opts.scaleLength) * opts.index;
  const g = opts.g + ((max - opts.g) / opts.scaleLength) * opts.index;
  const b = opts.b + ((max - opts.b) / opts.scaleLength) * opts.index;
  return { r: Math.round(r), g: Math.round(g), b: Math.round(b) };
};

export const getToBlackColorMember = (opts) => {
  if (isNaN(opts.r + opts.g + opts.b)) return false;
  const r = opts.r - (opts.r / opts.scaleLength) * opts.index;
  const g = opts.g - (opts.g / opts.scaleLength) * opts.index;
  const b = opts.b - (opts.b / opts.scaleLength) * opts.index;
  return { r: Math.round(r), g: Math.round(g), b: Math.round(b) };
};

export const getToRedColorMember = (opts) => {
  if (isNaN(opts.r + opts.g + opts.b)) return false;
  const max = 255;
  const r = opts.r + ((max - opts.r) / opts.scaleLength) * opts.index;
  const g = opts.g - (opts.g / opts.scaleLength) * opts.index;
  const b = opts.b - (opts.b / opts.scaleLength) * opts.index;
  return { r: Math.round(r), g: Math.round(g), b: Math.round(b) };
};

export const getToGreenColorMember = (opts) => {
  if (isNaN(opts.r + opts.g + opts.b)) return false;
  const max = 255;
  const r = opts.r - (opts.r / opts.scaleLength) * opts.index;
  const g = opts.g + ((max - opts.g) / opts.scaleLength) * opts.index;
  const b = opts.b - (opts.b / opts.scaleLength) * opts.index;
  return { r: Math.round(r), g: Math.round(g), b: Math.round(b) };
};

export const getToBlueColorMember = (opts) => {
  if (isNaN(opts.r + opts.g + opts.b)) return false;
  const max = 255;
  const r = opts.r - (opts.r / opts.scaleLength) * opts.index;
  const g = opts.g - (opts.g / opts.scaleLength) * opts.index;
  const b = opts.b + ((max - opts.b) / opts.scaleLength) * opts.index;
  return { r: Math.round(r), g: Math.round(g), b: Math.round(b) };
};

export const getToHsbHColorMember = (opts) => {
  if (isNaN(opts.r + opts.g + opts.b)) return false;

  // 色相環を360度一周するスケール
  // hsbのhのみが操作対象。

  // 処理を行うためhsbに変換
  const hsbArr = rgbToHsb([opts.r, opts.g, opts.b]);

  // 全体で進べき度数の算出。一周してしまうと基準色と同じ色になってしまうため、
  // 分割スケール数の一回分マイナスしておく
  const allRestW = 360 - 360 / opts.scaleLength;

  // このスケールが進む距離
  const w = (allRestW / opts.scaleLength) * opts.index;

  // 進距離が360度を超えると続きは0度からスタート
  const rtnHsbH = hsbArr[0] + w <= 360 ? hsbArr[0] + w : hsbArr[0] + w - 360;

  const hsbH = rtnHsbH;
  const hsbS = hsbArr[1];
  const hsbB = hsbArr[2];

  // 再度rgbに変換し返却
  const rgbArr = hsbToRgb([hsbH, hsbS, hsbB]);

  return {
    r: Math.round(rgbArr[0]),
    g: Math.round(rgbArr[1]),
    b: Math.round(rgbArr[2]),
  };

  // return { r: 255, g: 255, b: 255 };
};

export const getToSomeColorMember = (opts) => {
  if (
    isNaN(
      opts.baseColorR +
        opts.baseColorG +
        opts.baseColorB +
        opts.endColorR +
        opts.endColorG +
        opts.endColorB
    )
  )
    return false;

  let r =
    opts.baseColorR > opts.endColorR
      ? opts.baseColorR -
        ((opts.baseColorR - opts.endColorR) / opts.scaleLength) * opts.index
      : opts.baseColorR +
        ((opts.endColorR - opts.baseColorR) / opts.scaleLength) * opts.index;
  let g =
    opts.baseColorG > opts.endColorR
      ? opts.baseColorG -
        ((opts.baseColorG - opts.endColorG) / opts.scaleLength) * opts.index
      : opts.baseColorG +
        ((opts.endColorG - opts.baseColorG) / opts.scaleLength) * opts.index;
  let b =
    opts.baseColorB > opts.endColorR
      ? opts.baseColorB -
        ((opts.baseColorB - opts.endColorB) / opts.scaleLength) * opts.index
      : opts.baseColorB +
        ((opts.endColorB - opts.baseColorB) / opts.scaleLength) * opts.index;
  return { r: Math.round(r), g: Math.round(g), b: Math.round(b) };
};

export function getBootstrap5ColorMember(opts = {}) {
  let r, g, b;

  function tint(color) {
    return Math.round(((color - 255) / 100) * (100 - opts.bsDeviation) + 255);
  }

  function shade(color) {
    return Math.round((color / 100) * (100 - opts.bsDeviation));
  }

  if (opts.bsIndex < 500) {
    r = tint(opts.baseR);
    g = tint(opts.baseG);
    b = tint(opts.baseB);
  }

  if (opts.bsIndex === 500) {
    r = opts.baseR;
    g = opts.baseG;
    b = opts.baseB;
  }

  if (opts.bsIndex >= 600) {
    r = shade(opts.baseR);
    g = shade(opts.baseG);
    b = shade(opts.baseB);
  }

  return { r, g, b };
}

export function getColorTipInfoFromColorScale(opts = {}) {
  const scaleType = opts.scaleType;
  const scaleLength = opts.scaleLength;
  const index = opts.index;
  const baseR = opts.r;
  const baseG = opts.g;
  const baseB = opts.b;
  const bsDeviation = opts.bsDeviation;

  switch (scaleType) {
    case 'white':
      return getToWhiteColorMember({
        r: baseR,
        g: baseG,
        b: baseB,
        scaleLength: scaleLength - 1,
        index: index,
      });
    case 'white_not_include_end':
      return getToWhiteColorMember({
        r: baseR,
        g: baseG,
        b: baseB,
        scaleLength: scaleLength,
        index: index,
      });
    case 'black':
      return getToBlackColorMember({
        r: baseR,
        g: baseG,
        b: baseB,
        scaleLength: scaleLength - 1,
        index: index,
      });
    case 'black_not_include_end':
      return getToBlackColorMember({
        r: baseR,
        g: baseG,
        b: baseB,
        scaleLength: scaleLength,
        index: index,
      });
    case 'red':
      return getToRedColorMember({
        r: baseR,
        g: baseG,
        b: baseB,
        scaleLength: scaleLength - 1,
        index: index,
      });
    case 'green':
      return getToGreenColorMember({
        r: baseR,
        g: baseG,
        b: baseB,
        scaleLength: scaleLength - 1,
        index: index,
      });
    case 'blue':
      return getToBlueColorMember({
        r: baseR,
        g: baseG,
        b: baseB,
        scaleLength: scaleLength - 1,
        index: index,
      });
    case 'hsb_h':
      return getToHsbHColorMember({
        r: baseR,
        g: baseG,
        b: baseB,
        scaleLength: scaleLength - 1,
        index: index,
      });
    case 'complementary':
      const max = Math.max(baseR, Math.max(baseG, baseB));
      const min = Math.min(baseR, Math.min(baseG, baseB));
      const sum = max + min;
      const endColorR = sum - baseR;
      const endColorG = sum - baseG;
      const endColorB = sum - baseB;
      return getToSomeColorMember({
        baseColorR: baseR,
        baseColorG: baseG,
        baseColorB: baseB,
        endColorR: endColorR,
        endColorG: endColorG,
        endColorB: endColorB,
        scaleLength: scaleLength - 1,
        index: index,
      });
    case 'bootstrap5':
      return getBootstrap5ColorMember({
        baseR: baseR,
        baseG: baseG,
        baseB: baseB,
        bsIndex: (index + 1) * 100,
        bsDeviation: bsDeviation,
      });
    default:
      return getToWhiteColorMember({
        r: baseR,
        g: baseG,
        b: baseB,
        scaleLength: scaleLength,
        index: index,
      });
  }
}
