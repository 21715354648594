import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeScaleTipBsDeviationPercent } from '../../redux/bsDeviationPercentsSlice';

function ScaleTipBsDeviationInput(props) {
  const dispatch = useDispatch();
  const deviationPercents = useSelector((state) => state.bsDeviationPercents);

  const handleChange = (e) => {
    dispatch(
      changeScaleTipBsDeviationPercent({
        value: e.target.value,
        index: props.index,
      })
    );
  };

  return (
    <input
      onChange={(e) => {
        handleChange(e);
      }}
      className="scale-tip-bs-deviation-input"
      type="text"
      value={deviationPercents[props.index]}
    />
  );
}

export default ScaleTipBsDeviationInput;
