import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: { en: {}, ja: {} } };

export const wordsSlice = createSlice({
  name: 'words',
  initialState,
  reducers: {
    initWords: (state, action) => {
      state.value = action.payload;
    },
    changeWords: (state, action) => {
      // tmp
      state.value = action.value;
    },
  },
});

export const { initWords, changeWords } = wordsSlice.actions;
export default wordsSlice.reducer;
