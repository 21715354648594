import { createSlice } from '@reduxjs/toolkit';
import { getStrage } from '../helpers/strage';

const storage = getStrage('setting');
const defaultState = { value: 10 };
const initialState = storage.hasOwnProperty('scaleNum')
  ? { value: storage.scaleNum }
  : defaultState;

export const scaleNumSlice = createSlice({
  name: 'scaleNum',
  initialState,
  reducers: {
    changeScaleNumInput: (state, action) => {
      const v = action.payload.value;
      const n = Number(v);
      if (v === '') state.value = '';
      if (n >= 0 && n <= 50) {
        state.value = v;
      }
    },
    initScaleNumInput: (state, action) => {
      state.value = defaultState.value;
    },
  },
});

export const { changeScaleNumInput, initScaleNumInput } = scaleNumSlice.actions;

export default scaleNumSlice.reducer;
