import React from 'react';
import { useSelector } from 'react-redux';
import logo from '../images/logo.png';
// Components
import SectionTitle from '../components/SectionTitle';
// Layout Components
import BaseSection from './BaseSection';
import OptionSection from './OptionSection';
import ScalesSection from './ScalesSection';

function Main() {
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];

  return (
    <main className="app-main">
      <div className="container mt-3 mt-md-1 mb-5">
        <div className="row justify-content-center">
          <div className="col-auto">
            <img src={logo} className="mainvisual" alt="Color Scale Web" />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-7 mb-4">
            <div className="app-section h-100">
              <SectionTitle title={locale.base_section_title} />
              <BaseSection />
            </div>
          </div>
          <div className="col-12 col-lg-5 mb-4">
            <div className="app-section h-100">
              <SectionTitle title={locale.option_section_title} />
              <OptionSection />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="app-section">
              <SectionTitle title={locale.scales_section_title} />
              <div className="app-section-body">
                <ScalesSection />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Main;
