export default function showPopup(opts = {}) {
  // opts
  const displayNode = opts.displayNode;
  const triggerNode = opts.triggerNode;
  const animStepLength = opts.animStepLength || 40;
  const animInterval = opts.animInterval || 10;
  const animXDistance = opts.animXDistance || 0;
  const animYDistance = opts.animYDistance || -30;
  const animHideInterval = opts.animHideInterval || 500;

  // node size
  const displayNodeWidth = displayNode.clientWidth;
  const displayNodeHeight = displayNode.clientHeight;
  const triggerNodeWidth = triggerNode.clientWidth;
  const triggerNodeHeight = triggerNode.clientHeight;

  // node location
  const triggerNodeRect = triggerNode.getBoundingClientRect();
  const triggerNodeRectTop = triggerNodeRect.top;
  const triggerNodeRectLeft = triggerNodeRect.left;

  // node starting point and destination
  const displayNodeStartLeftLoc =
    window.pageXOffset +
    triggerNodeRectLeft +
    triggerNodeWidth / 2 -
    displayNodeWidth / 2;
  const displayNodeStartTopLoc =
    window.pageYOffset +
    triggerNodeRectTop +
    triggerNodeHeight / 2 -
    displayNodeHeight / 2;

  displayNode.style.left = `${displayNodeStartLeftLoc}px`;
  displayNode.style.top = `${displayNodeStartTopLoc}px`;
  displayNode.style.opacity = 1;

  let step = 0;

  function run() {
    step++;
    displayNode.style.opacity = (0.8 / animStepLength) * step;

    displayNode.style.left = `${
      displayNodeStartLeftLoc + (animXDistance / animStepLength) * step
    }px`;

    displayNode.style.top = `${
      displayNodeStartTopLoc + (animYDistance / animStepLength) * step
    }px`;

    let timer = setTimeout(run, animInterval);

    if (step >= animStepLength) {
      clearTimeout(timer);
      setTimeout(() => {
        displayNode.style.visibility = 'hidden';
        displayNode.style.left = `${displayNodeStartLeftLoc}px`;
        displayNode.style.top = `${displayNodeStartTopLoc}px`;
        displayNode.remove();
      }, animHideInterval);
    }
  }

  run();
}
