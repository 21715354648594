// ---------------------------
// [ define words ]
//
// hex => AA3456
// rgb => [0, 45, 255]
// hexWhite => fff or ffffff or FFF or FFFFFF
// ---------------------------

export const convertHex3To6Digits = (_v) => {
  let v = String(_v);
  v = Array.from(v);
  v = `${v[0]}${v[0]}${v[1]}${v[1]}${v[2]}${v[2]}`;
  return v;
};

export const convertHex6To3Digits = (_v) => {
  let v = String(_v);
  v = Array.from(v);
  v = `${v[0]}${v[2]}${v[4]}`;
  return v;
};

export const isHex = (v) => {
  if (String(v).length === 3) return /[0-9A-Fa-f]{3}/g.test(v);
  if (String(v).length === 6) return /[0-9A-Fa-f]{6}/g.test(v);
  return false;
};

export const isHexWhite = (v) => {
  if (String(v).length === 3) return /[f]{3}/gi.test(v);
  if (String(v).length === 6) return /[f]{6}/gi.test(v);
  return false;
};

export const isContainNonHexChar = (v) => {
  const arr = v.split('');
  for (let i = 0; i < arr.length; i++) {
    if (!/[0-9A-Fa-f]/g.test(arr[i])) return true;
  }
  return false;
};

export const getRandomHex = () => {
  const chars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
  ];
  let rtnVal = '';
  for (let i = 0; i < 6; i++) {
    const s = chars[Math.floor(Math.random() * chars.length)];
    rtnVal += s;
  }
  return rtnVal;
};

export const isRgbMember = (v) => {
  const n = Number(v);
  if (v === '' || n < 0) return false;
  if (n === 0) return true;
  if (!n) return false;
  if (n > 255) return false;

  return true;
};

export const rgbToHex = (rgbArr) => {
  if (isNaN(rgbArr[0] + rgbArr[1] + rgbArr[2])) return false;

  return rgbArr
    .map(function (value) {
      return ('0' + value.toString(16)).slice(-2);
    })
    .join('');
};

export const hexToRgb = (s) => {
  let hex = s;

  // HEX Color文字列でない場合除外
  if (!isHex(hex) || (hex.length !== 3 && hex.length !== 6)) return false;

  //HEX Colorが3桁の場合は6桁に
  if (hex.length === 3) {
    const hexAsArr = String(s).split('');
    let rtnS = '';
    hexAsArr.forEach((s) => {
      rtnS += s;
      rtnS += s;
    });
    hex = rtnS;
  }

  // AB12EF => ['AB', '12', 'EF']
  const hexSplitArr = hex.match(/.{1,2}/g);

  // ['AB', '12', 'EF'] => [171, 18, 239]
  const rgbArr = [
    parseInt(hexSplitArr[0], 16),
    parseInt(hexSplitArr[1], 16),
    parseInt(hexSplitArr[2], 16),
  ];

  return rgbArr;
};

export const rgbToHsb = (rgbArr) => {
  if (isNaN(isNaN(rgbArr[0]) + isNaN(rgbArr[1]) + isNaN(rgbArr[2])))
    return false;

  const rgbR = Number(rgbArr[0]);
  const rgbG = Number(rgbArr[1]);
  const rgbB = Number(rgbArr[2]);

  // [note] 受け取った値とそれがrgbのどれに当たるかをマップ

  let maxColor = null;
  const maxVal = Math.max(rgbR, rgbG, rgbB);
  const minVal = Math.min(rgbR, rgbG, rgbB);

  // for (let i = 0; i < rgbArr.length; i++) {
  //   if (rgbArr[i] === maxVal) {
  //     switch (i) {
  //       case 0:
  //         maxColor = 'r';
  //         break;
  //       case 1:
  //         maxColor = 'g';
  //         break;

  //       case 2:
  //         maxColor = 'b';
  //         break;
  //       default:
  //         break;
  //     }
  //   }
  // }

  rgbArr.forEach((val, index) => {
    if (maxVal === val) {
      switch (index) {
        case 0:
          maxColor = 'r';
          break;
        case 1:
          maxColor = 'g';
          break;
        case 2:
          maxColor = 'b';
          break;
        default:
          break;
      }
    }
  });

  // [ref] https://yanohirota.com/color-converter/
  let hsbH = null;
  let hsbS = null;
  let hsbB = null;

  switch (maxColor) {
    case 'r':
      hsbH = ((rgbG - rgbB) / (maxVal - minVal)) * 60;
      break;
    case 'g':
      hsbH = ((rgbB - rgbR) / (maxVal - minVal)) * 60 + 120;

      break;
    case 'b':
      hsbH = ((rgbR - rgbG) / (maxVal - minVal)) * 60 + 240;
      break;
    default:
      break;
  }

  hsbS = ((maxVal - minVal) / maxVal) * 100;
  hsbB = (maxVal / 255) * 100;

  // NaNやマイナスの値が出てしまった時の処理
  hsbH = isNaN(hsbH) ? 0 : hsbH;
  hsbH = hsbH < 0 ? 360 + hsbH : hsbH;
  hsbS = isNaN(hsbS) ? 0 : hsbS;
  hsbB = isNaN(hsbB) ? 0 : hsbB;

  return [hsbH, hsbS, hsbB];
};

export const hsbToRgb = (hsbArr) => {
  const hsbH = Number(hsbArr[0]) === 360 ? 0 : Number(hsbArr[0]);
  const hsbS = Number(hsbArr[1]);
  const hsbB = Number(hsbArr[2]);

  // [ref] https://yanohirota.com/color-converter/
  // [note] x_ これらは計算上で必要な値群
  const h_ = (hsbH / 60) % 1;
  const s_ = hsbS / 100;
  const b_ = hsbB / 100;
  const A_ = b_ * 255;
  const B_ = b_ * (1 - s_) * 255;
  const C_ = b_ * (1 - s_ * h_) * 255;
  const D_ = b_ * (1 - s_ * (1 - h_)) * 255;

  if (hsbS === 0) {
    return [A_, A_, A_];
  }

  if (hsbH >= 0 && hsbH < 60) {
    return [A_, D_, B_];
  }

  if (hsbH >= 60 && hsbH < 120) {
    return [C_, A_, B_];
  }

  if (hsbH >= 120 && hsbH < 180) {
    return [B_, A_, D_];
  }

  if (hsbH >= 180 && hsbH < 240) {
    return [B_, C_, A_];
  }

  if (hsbH >= 240 && hsbH < 300) {
    return [D_, B_, A_];
  }

  if (hsbH >= 300 && hsbH < 360) {
    return [A_, B_, C_];
  }

  return false;
};
