import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from '../redux/preferenceSlice';
import { setStrage } from '../helpers/strage';
// Components
import Radio from '../components/Radio';

function SettingCanvasLanguage() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const language = useSelector((state) => state.preference.language);
  const locale = words[language];

  const bindChange = (e) => {
    dispatch(changeLanguage(e.target.value));
  };

  setStrage('setting', { language });

  return (
    <div>
      <div className="d-inline-block me-2">
        <Radio
          onChange={bindChange.bind(this)}
          id="settingCanvasLanguageRadioEn"
          title={locale.setting_canvas_lang_sec_radio_en}
          groupName="settingCanvasLanguageRadio"
          value="en"
          checked={language === 'en' ? true : false}
        />
      </div>
      <div className="d-inline-block">
        <Radio
          onChange={bindChange.bind(this)}
          id="settingCanvasLanguageRadioJa"
          title={locale.setting_canvas_lang_sec_radio_ja}
          groupName="settingCanvasLanguageRadio"
          value="ja"
          checked={language === 'ja' ? true : false}
        />
      </div>
    </div>
  );
}

export default SettingCanvasLanguage;
