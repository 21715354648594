import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal, showBackdrop } from '../../redux/layoutsSlice';
import { changeModalContent } from '../../redux/modalContentSlice';

import QuestionCircle from '../../components/icons/QuestionCircle';

function HelpModalOpener() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(changeModalContent({ value: 'help' }));
    dispatch(showBackdrop());
    dispatch(showModal());
  };

  return (
    <div
      className="help-modal-opener"
      onClick={() => {
        handleClick();
      }}
    >
      <QuestionCircle width="1.5rem" height="1.5rem" />
    </div>
  );
}

export default HelpModalOpener;
