import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initPreference } from '../../redux/preferenceSlice';
import { initScaleNumInput } from '../../redux/scaleNumSlice';
import { initHexInput } from '../../redux/colorHexSlice';
import { initScaleTipSize } from '../../redux/scaleTipSizeSlice';
import { initColorTipContents } from '../../redux/colorTipContentsSlice';

import { removeStrage } from '../../helpers/strage';
import popupEffect from '../../helpers/popupEffect';
import getAndInsertPopupNodeToBody from '../../helpers/getAndInsertPopupNodeToBody';
import Btn from '../../components/Btn';

function ResetSettingBtn() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const colorHex = useSelector((state) => state.colorHex.value);

  const showResetPopup = (triggerNode) => {
    popupEffect({
      displayNode: getAndInsertPopupNodeToBody({
        innerText: locale.app_reset,
      }),
      triggerNode: triggerNode,
      animStepLength: 10,
      animYDistance: -45,
    });
  };

  const handleClick = (e) => {
    removeStrage('setting');
    // [note] HEX値だけ今選択中のものをそのまま利用。(リセットしない)
    // そのため、その情報だけpayloadに渡す。
    dispatch(initPreference());
    dispatch(initHexInput(colorHex));
    dispatch(initScaleNumInput());
    dispatch(initScaleTipSize());
    dispatch(initColorTipContents());

    showResetPopup(e.target);
  };

  return (
    <Btn
      onClick={handleClick.bind(this)}
      listIcon="Trash"
      title={locale.setting_canvas_reset_sec_btn_reset_setting}
    />
  );
}

export default ResetSettingBtn;
