import { useSelector } from 'react-redux';
// Components
import SectionSubTitle from '../components/SectionSubTitle';
// Named Components
import ScaleNumInput from '../components_named/OptionSection/ScaleNumInput';
import ScaleTipContentsCks from '../components_named/OptionSection/ScaleTipContentsCks';
import RadiosScaleTipSize from '../components_named/OptionSection/ScaleTipSizeRadios';

function OptionSection(props) {
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];

  return (
    <div className="app-section-body">
      <div className="mb-3">
        <SectionSubTitle
          title={locale.option_section_color_scale_num_input_title}
          note="(2-50)"
        />
        <ScaleNumInput />
      </div>
      <div className="mb-3">
        <SectionSubTitle
          title={locale.option_section_color_tip_contents_input_title}
        />
        <ScaleTipContentsCks />
      </div>
      <div className="mb-3">
        <SectionSubTitle
          title={locale.option_section_color_tip_size_input_title}
        />
        <RadiosScaleTipSize />
      </div>
    </div>
  );
}

export default OptionSection;
