import React from 'react';
import { useDispatch } from 'react-redux';
import { showModal, showBackdrop } from '../../redux/layoutsSlice';
import { changeModalContent } from '../../redux/modalContentSlice';
import QuestionCircle from '../../components/icons/QuestionCircle';

function IconBtnBsScaleModal() {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(changeModalContent({ value: 'bs' }));
    dispatch(showBackdrop());
    dispatch(showModal());
  };

  return (
    <div
      onClick={() => {
        handleClick();
      }}
      className="m1s-1 scale-item__help-icon d-inline-block"
    >
      <QuestionCircle width="15px" height="15px" />
    </div>
  );
}

export default IconBtnBsScaleModal;
