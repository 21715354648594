import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { initWords } from '../redux/wordsSlice';
import { changeHexInput } from '../redux/colorHexSlice';
// Components
import Backdrop from '../components/Backdrop';
import Modal from '../components/Modal';
// Layout Components
import Header from './Header';
import Footer from './Footer';
import Main from './Main';
import SettingCanvas from './SettingCanvas';
// import DebugWindow from '../components/DebugWindow';

function App() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.preference.theme);
  const hexCase = useSelector((state) => state.preference.hexCase);
  const colorHex = useSelector((state) => state.colorHex.value);

  // [todo]
  // 非同期で取得するデータにおいて、データが取得できていない場合に
  // 子コンポーネントでエラーが出ることがある。これを回避する方法がわからない
  // localeファイルの生成
  const fetchAndSetWords = async () => {
    const words = {};
    const res1 = await fetch('/locales/en.json');
    const res2 = await fetch('/locales/ja.json');
    const results1 = await res1.json();
    const results2 = await res2.json();
    words.en = await results1;
    words.ja = await results2;
    dispatch(initWords(words));
  };

  // 初回マウント時のみしか実行してはいけない
  useEffect(() => {
    // 起点となるHEX値を作成
    dispatch(changeHexInput({ value: colorHex, hexCase }));
    // 言語ファイル取得
    fetchAndSetWords();
  }, []);

  return (
    <div className={`app app--theme_${theme.themeId} ${colorHex}`}>
      <div className="app-bg-layer-1">
        {/* <DebugWindow /> */}
        <Modal />
        <Backdrop />
        <SettingCanvas />
        <Header />
        <Main />
        <Footer />
      </div>
    </div>
  );
}

export default App;
