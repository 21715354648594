import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeScaleTipSize } from '../../redux/scaleTipSizeSlice';
import { setStrage } from '../../helpers/strage';
import Radio from '../../components/Radio';

function ScaleTipSizeRadios() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const scaleTipSize = useSelector((state) => state.scaleTipSize.value);

  const bindChange = (e) => {
    dispatch(changeScaleTipSize({ value: e.target.value }));
  };

  setStrage('setting', { scaleTipSize });

  return (
    <div>
      <div className="d-inline-block me-2">
        <Radio
          onChange={bindChange.bind(this)}
          id="scaleTipSizeRadioS"
          title={locale.option_section_color_tip_size_input_label_s}
          groupName="scaleTipSizeRadio"
          value="s"
          checked={scaleTipSize === 's' ? true : false}
        />
      </div>
      <div className="d-inline-block me-2">
        <Radio
          onChange={bindChange.bind(this)}
          id="scaleTipSizeRadioM"
          title={locale.option_section_color_tip_size_input_label_m}
          groupName="scaleTipSizeRadio"
          value="m"
          checked={scaleTipSize === 'm' ? true : false}
        />
      </div>
      <div className="d-inline-block">
        <Radio
          onChange={bindChange.bind(this)}
          id="scaleTipSizeRadioL"
          title={locale.option_section_color_tip_size_input_label_l}
          groupName="scaleTipSizeRadio"
          value="l"
          checked={scaleTipSize === 'l' ? true : false}
        />
      </div>
    </div>
  );
}

export default ScaleTipSizeRadios;
