import { createSlice, createAction } from '@reduxjs/toolkit';
import { hexToRgb, isHex, isRgbMember } from '../helpers/colorHelpers';
import { getStrage } from '../helpers/strage';

const strage = getStrage('setting');
const initialState = strage.hasOwnProperty('colorG')
  ? { value: strage.colorG }
  : { value: 0 };

// extra action
const colorHex_changeHexInput = createAction(
  'colorHex/changeHexInput',
  function prepare(text) {}
);

export const colorGSlice = createSlice({
  name: 'colorG',
  initialState,
  reducers: {
    changeGInput: (state, action) => {
      let v = action.payload.value;
      if (v === '') state.value = '';
      if (isRgbMember(v)) {
        v = Number(v);
        state.value = v;
      }
    },
  },
  extraReducers: {
    [colorHex_changeHexInput]: (state, action) => {
      let v = action.payload.value;
      // // 1文字目が#であるケースの処理
      if (v.charAt(0) === '#') v = v.slice(1);
      if (isHex(v)) {
        v = hexToRgb(v)[1];
        state.value = v;
      }
    },
  },
});

export const { changeGInput } = colorGSlice.actions;
export default colorGSlice.reducer;
