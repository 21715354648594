import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeColorFromFavs } from '../../redux/favColorsSlice';
import {
  convertHex3To6Digits,
  isHex,
  isRgbMember,
} from '../../helpers/colorHelpers';
import { getStrage, setStrage } from '../../helpers/strage';
import Btn from '../../components/Btn';

function BtnRemoveColorFromFavs() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const colorHex = useSelector((state) => state.colorHex.value);
  const colorR = useSelector((state) => state.colorR.value);
  const colorG = useSelector((state) => state.colorG.value);
  const colorB = useSelector((state) => state.colorB.value);
  const favColors = useSelector((state) => state.favColors.colors);

  const bindClick = () => {
    let crHex = colorHex;
    crHex = crHex.length === 6 ? crHex : convertHex3To6Digits(crHex);
    crHex = crHex.toUpperCase();

    let favsColorsStrage = getStrage('favs').colors
      ? getStrage('favs').colors
      : [];

    // [note] お気に入りから削除 & ストレージから削除
    if (favColors.includes(crHex)) {
      dispatch(removeColorFromFavs(crHex));
      favsColorsStrage = favsColorsStrage.filter((color) => {
        return color !== crHex;
      });
      setStrage('favs', { colors: favsColorsStrage });
      return false;
    }
  };

  return (
    <Btn
      onClick={
        isHex(colorHex) &&
        isRgbMember(colorR) &&
        isRgbMember(colorG) &&
        isRgbMember(colorB)
          ? bindClick.bind(this)
          : () => {
              return false;
            }
      }
      listIcon="Trash"
      title={locale.base_section_remove_btn}
      colorType={
        isHex(colorHex) &&
        isRgbMember(colorR) &&
        isRgbMember(colorG) &&
        isRgbMember(colorB)
          ? 'delete'
          : 'disabled'
      }
    />
  );
}

export default BtnRemoveColorFromFavs;
