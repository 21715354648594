import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addColorToFavs } from '../../redux/favColorsSlice';
import {
  convertHex3To6Digits,
  isHex,
  isRgbMember,
} from '../../helpers/colorHelpers';
import popupEffect from '../../helpers/popupEffect';
import getAndInsertPopupNodeToBody from '../../helpers/getAndInsertPopupNodeToBody';
import { getStrage, setStrage } from '../../helpers/strage';
import Btn from '../../components/Btn';

function BtnAddColorToFavs() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const colorHex = useSelector((state) => state.colorHex.value);
  const colorR = useSelector((state) => state.colorR.value);
  const colorG = useSelector((state) => state.colorG.value);
  const colorB = useSelector((state) => state.colorB.value);
  const favColors = useSelector((state) => state.favColors.colors);

  const showAddedPopup = (triggerNode) => {
    popupEffect({
      displayNode: getAndInsertPopupNodeToBody({
        innerText: locale.app_added,
      }),
      triggerNode: triggerNode,
      animStepLength: 10,
      animYDistance: -55,
    });
  };

  const bindClick = (e) => {
    let crHex = colorHex;
    crHex = crHex.length === 6 ? crHex : convertHex3To6Digits(crHex);
    crHex = crHex.toUpperCase();

    let favsColorsStrage = getStrage('favs').colors
      ? getStrage('favs').colors
      : [];

    if (!favColors.includes(crHex)) {
      showAddedPopup(e.target);
      dispatch(addColorToFavs(crHex));
      favsColorsStrage.push(crHex);
      setStrage('favs', { colors: favsColorsStrage });
    }
  };

  return (
    <Btn
      onClick={
        isHex(colorHex) &&
        isRgbMember(colorR) &&
        isRgbMember(colorG) &&
        isRgbMember(colorB)
          ? bindClick.bind(this)
          : () => {
              return false;
            }
      }
      listIcon="StarFill"
      title={locale.base_section_add_btn}
      colorType={
        isHex(colorHex) &&
        isRgbMember(colorR) &&
        isRgbMember(colorG) &&
        isRgbMember(colorB)
          ? 'primary'
          : 'disabled'
      }
    />
  );
}

export default BtnAddColorToFavs;
