import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideBackdrop, hideModal } from '../redux/layoutsSlice';
import HelpModalContent from '../components_named/Modal/HelpModalContent';
import FavsModalContent from '../components_named/Modal/FavsModalContent';
import BsScaleModalContent from '../components_named/Modal/BsScaleModalContent';
import CloseBtn from '../components_named/Modal/CloseBtn';

function Modal() {
  const dispatch = useDispatch();
  const modalId = useSelector((state) => state.modalContent.value);
  const isShownModal = useSelector((state) => state.layouts.isShownModal);

  const handleModalCloseBtnClick = () => {
    dispatch(hideModal());
    dispatch(hideBackdrop());
  };

  return (
    <div>
      <div
        onClick={() => {
          dispatch(hideModal());
          dispatch(hideBackdrop());
        }}
        className={`app-modal ${isShownModal ? 'show' : ''}`}
      >
        <div
          className="app-modal-dialog"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="app-modal-content">
            <CloseBtn
              onClick={handleModalCloseBtnClick.bind(this)}
              width="1.6rem"
              height="1.6rem"
            />
            <div className="app-modal-body">
              {modalId === 'help' && <HelpModalContent />}
              {modalId === 'favs' && <FavsModalContent />}
              {modalId === 'bs' && <BsScaleModalContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
