import React from 'react';
import { useSelector } from 'react-redux';
import getAndInsertPopupNodeToBody from '../../helpers/getAndInsertPopupNodeToBody';
import popupEffect from '../../helpers/popupEffect';
import copyToClipboard from '../../helpers/copyToClipboard';
import { rgbToHex, isHex } from '../../helpers/colorHelpers';
import { getColorTipInfoFromColorScale } from '../../helpers/colorScaleHelpers';
import ScaleUtilLink from './ScaleUtilLink';

function ScaleTipUtilities(props) {
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const colorHex = useSelector((state) => state.colorHex.value);
  const colorR = useSelector((state) => state.colorR.value);
  const colorG = useSelector((state) => state.colorG.value);
  const colorB = useSelector((state) => state.colorB.value);
  const scaleNum = useSelector((state) => state.scaleNum.value);
  const deviationPercents = useSelector((state) => state.bsDeviationPercents);

  const isBootstrapAndIsNotTheFixScaleNum = () => {
    return props.scaleType === 'bootstrap5' && scaleNum !== 9;
  };

  const isBootstrap = () => {
    return props.scaleType === 'bootstrap5';
  };

  const showCopiedPopup = (triggerNode) => {
    popupEffect({
      displayNode: getAndInsertPopupNodeToBody({
        innerText: locale.app_copied,
      }),
      triggerNode: triggerNode,
      animStepLength: 10,
      animYDistance: -40,
    });
  };

  const getAllColorText = (colorType) => {
    let rtnS = '';
    const scaleLength = isBootstrap() ? 9 : scaleNum;
    const scaleType = props.scaleType;
    const indexRatio = scaleType === 'bootstrap5' ? 100 : 1;
    // 基準色から全てのカラーをもらってくる
    if (scaleLength > 1) {
      if (colorType === 'hex-bs')
        rtnS += `$color: #${rgbToHex([colorR, colorG, colorB])};\n`;

      if (colorType === 'rgb-bs')
        rtnS += `$color: rgb(${colorR}, ${colorG}, ${colorB});\n`;

      for (let i = 0; i < scaleLength; i++) {
        // bootstrapのみの乖離率の値
        const bsDeviation =
          scaleType === 'bootstrap5'
            ? deviationPercents[(i + 1) * indexRatio]
            : false;
        const info = getColorTipInfoFromColorScale({
          scaleType: scaleType,
          scaleLength: scaleLength,
          index: i,
          r: colorR,
          g: colorG,
          b: colorB,
          bsDeviation: bsDeviation,
        });

        if (colorType === 'rgb')
          rtnS += `rgb(${info.r}, ${info.g}, ${info.b})\n`;

        if (colorType === 'hex')
          rtnS += `#${rgbToHex([info.r, info.g, info.b])}\n`;

        if (colorType === 'rgb-bs') {
          if (i !== 4) {
            rtnS += `$color-${(i + 1) * 100}: rgb(${info.r}, ${info.g}, ${
              info.b
            });\n`;
          } else {
            rtnS += `$color-500: $color;\n`;
          }
        }

        if (colorType === 'hex-bs') {
          if (i !== 4) {
            rtnS += `$color-${(i + 1) * 100}: #${rgbToHex([
              info.r,
              info.g,
              info.b,
            ])};\n`;
          } else {
            rtnS += `$color-500: $color;\n`;
          }
        }
      }
    }

    return rtnS;
  };

  const bindCopyAllHex = (e) => {
    const text = getAllColorText('hex');
    copyToClipboard(text);
    showCopiedPopup(e.target);
  };

  const bindCopyAllRgb = (e) => {
    const text = getAllColorText('rgb');
    copyToClipboard(text);
    showCopiedPopup(e.target);
  };

  const bindCopyAllHexScss = (e) => {
    const text = getAllColorText('hex-bs');
    copyToClipboard(text);
    showCopiedPopup(e.target);
  };

  const bindCopyAllRgbScss = (e) => {
    const text = getAllColorText('rgb-bs');
    copyToClipboard(text);
    showCopiedPopup(e.target);
  };

  return (
    <div className="scale-util mb-2">
      <div className="scale-util-step">
        {locale.scales_section_step_label} :
        <span
          className={`${
            isBootstrapAndIsNotTheFixScaleNum()
              ? 'text-strike-and-disabled'
              : ''
          }`}
        >
          {scaleNum}
        </span>
        {isBootstrapAndIsNotTheFixScaleNum() && <span className="ps-1">9</span>}
      </div>

      <div className="d-flex">
        <ScaleUtilLink
          onClick={
            isHex(colorHex)
              ? bindCopyAllHex.bind(this)
              : () => {
                  return false;
                }
          }
          title={locale.scales_section_copy_link_hex}
          additionalClass={
            !isHex(colorHex) && 'scale-util-link--state_disabled'
          }
        />

        <ScaleUtilLink
          onClick={
            isHex(colorHex)
              ? bindCopyAllRgb.bind(this)
              : () => {
                  return false;
                }
          }
          title={locale.scales_section_copy_link_rgb}
          additionalClass={
            !isHex(colorHex) && 'scale-util-link--state_disabled'
          }
        />

        {isBootstrap() && (
          <div className="d-flex">
            <ScaleUtilLink
              onClick={
                isHex(colorHex)
                  ? bindCopyAllHexScss.bind(this)
                  : () => {
                      return false;
                    }
              }
              title={locale.scales_section_copy_link_hex_scss}
              additionalClass={
                !isHex(colorHex) && 'scale-util-link--state_disabled'
              }
            />
            <ScaleUtilLink
              onClick={
                isHex(colorHex)
                  ? bindCopyAllRgbScss.bind(this)
                  : () => {
                      return false;
                    }
              }
              title={locale.scales_section_copy_link_rgb_scss}
              additionalClass={
                !isHex(colorHex) && 'scale-util-link--state_disabled'
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ScaleTipUtilities;
