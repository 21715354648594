import React from 'react';
import { useDispatch } from 'react-redux';
import { hideBackdrop, toggleSettingCanvas } from '../../redux/layoutsSlice';
import ArrowRight from '../../components/icons/ArrowRight';

function Closer() {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(toggleSettingCanvas());
    dispatch(hideBackdrop());
  };

  return (
    <div
      onClick={() => {
        handleClick();
      }}
      className="setting-canvas-closer"
    >
      <ArrowRight width="18" height="18" />
    </div>
  );
}

export default Closer;
