import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeTheme } from '../redux/preferenceSlice';
import { setStrage } from '../helpers/strage';

function ThemeSelector(props) {
  const dispatch = useDispatch();
  const themeState = useSelector((state) => state.preference.theme);
  setStrage('setting', { theme: themeState });

  const handleClick = () => {
    if (themeState.themeId !== props.id) {
      dispatch(changeTheme(props.id));
    }
  };

  let activeClassName = '';

  if (themeState.themeId === props.id) {
    activeClassName = 'theme-selector--state_active';
  }

  return (
    <span
      className={`theme-selector theme-selector--theme_${props.id} ${activeClassName}`}
      onClick={() => {
        handleClick();
      }}
    ></span>
  );
}

export default ThemeSelector;
