import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  addColorToFavs,
  removeColorFromFavs,
} from '../../redux/favColorsSlice';
import { convertHex3To6Digits } from '../../helpers/colorHelpers';
import { getStrage, setStrage } from '../../helpers/strage';
import popupEffect from '../../helpers/popupEffect';
import getAndInsertPopupNodeToBody from '../../helpers/getAndInsertPopupNodeToBody';
import Star from '../../components/icons/Star';
import StarFill from '../../components/icons/StarFill';

function ColorTipStarIcon() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const colorHex = useSelector((state) => state.colorHex.value);
  const favColors = useSelector((state) => state.favColors.colors);

  let crHex = colorHex;
  crHex = crHex.length === 6 ? crHex : convertHex3To6Digits(crHex);
  crHex = crHex.toUpperCase();

  const showAddedPopup = (triggerNode) => {
    popupEffect({
      displayNode: getAndInsertPopupNodeToBody({
        innerText: locale.app_added,
      }),
      triggerNode: triggerNode,
      animStepLength: 10,
      animYDistance: -35,
    });
  };

  const handleClick = (e) => {
    // [note] [準備] お気に入りのstateとstrage両方を準備
    let favsColorsStrage = getStrage('favs').colors
      ? getStrage('favs').colors
      : [];

    // [note] 「Added!」の吹き出し表示
    if (!favColors.includes(crHex)) showAddedPopup(e.target);

    // [note] お気に入りから削除 & ストレージから削除
    if (favColors.includes(crHex)) {
      dispatch(removeColorFromFavs(crHex));
      favsColorsStrage = favsColorsStrage.filter((color) => {
        return color !== crHex;
      });
      setStrage('favs', { colors: favsColorsStrage });
      return false;
    }

    // [note] お気に入りに追加 & ストレージに追加
    if (!favColors.includes(crHex)) {
      dispatch(addColorToFavs(crHex));
      favsColorsStrage.push(crHex);
      setStrage('favs', { colors: favsColorsStrage });
      return false;
    }
  };

  return (
    <div
      className={`base-tip-star-icon ${
        favColors.includes(crHex) ? 'base-tip-star-icon--state_active' : ''
      }`}
      onClick={(e) => {
        handleClick(e);
      }}
    >
      {favColors.includes(crHex) && <StarFill width="1.2rem" height="1.2rem" />}
      {!favColors.includes(crHex) && <Star width="1.2rem" height="1.2rem" />}
    </div>
  );
}

export default ColorTipStarIcon;
