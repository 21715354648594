import React from 'react';
import { useSelector } from 'react-redux';
import { convertHex3To6Digits } from '../helpers/colorHelpers';
// components
import SectionSubTitle from '../components/SectionSubTitle';
// named components
import ColorTip from '../components_named/BaseSection/ColorTip';
import InputHex from '../components_named/BaseSection/InputHex';
import InputR from '../components_named/BaseSection/InputR';
import InputG from '../components_named/BaseSection/InputG';
import InputB from '../components_named/BaseSection/InputB';
import BtnOpenFavsModal from '../components_named/BaseSection/BtnOpenFavsModal';
import BtnAddColorToFavs from '../components_named/BaseSection/BtnAddColorToFavs';
import BtnRemoveColorFromFavs from '../components_named/BaseSection/BtnRemoveColorFromFavs';

function BaseSection(props) {
  const colorHex = useSelector((state) => state.colorHex.value);
  const favColors = useSelector((state) => state.favColors.colors);

  // [note] お気に入り追加ボタン or 削除ボタンを追加するかの判別
  let isFav = false;
  let crHex = colorHex;
  crHex = crHex.length === 6 ? crHex : convertHex3To6Digits(crHex);
  crHex = crHex.toUpperCase();
  if (favColors.includes(crHex)) isFav = true;

  return (
    <div className="app-section-body">
      <div className="app-base-color-main-clm">
        <div className="app-base-color-tip-clm">
          <ColorTip />
        </div>
        <div className="app-base-color-detail-clm">
          <div className="mb-3">
            <SectionSubTitle title="HEX" note="(0-9 &amp; A-F)" />
            <div>
              <span className="w-1rem d-inline-block">#</span>
              <InputHex />
            </div>
          </div>
          <div className="mb-2">
            <SectionSubTitle title="RGB" note="(0-255)" />
            <div className="me-2 mb-2 d-inline-block">
              <span className="w-1rem d-inline-block">R</span>
              <InputR />
            </div>
            <div className="me-2 mb-2 d-inline-block">
              <span className="w-1rem d-inline-block">G</span>
              <InputG />
            </div>
            <div className="me-2 mb-2 d-inline-block">
              <span className="w-1rem d-inline-block">B</span>
              <InputB />
            </div>
          </div>
          <div>
            <div className="d-inline-block me-2 mb-2">
              <BtnOpenFavsModal />
            </div>
            <div className="d-inline-block">
              {isFav ? <BtnRemoveColorFromFavs /> : <BtnAddColorToFavs />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BaseSection;
