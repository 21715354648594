export default function getAndInsertPopupNodeToBody(opts = {}) {
  // popupの作成
  const innerText = opts.innerText || 'text';
  const body = document.querySelector('body');
  const popup = document.createElement('div');
  const popupArrow = document.createElement('span');
  const popupText = document.createElement('div');
  popup.style.position = 'relative';
  popup.style.padding = '10px';
  popup.style.color = '#333';
  popup.style.backgroundColor = '#eee';
  popup.style.zIndex = 9999;
  popup.style.position = 'absolute';
  popup.style.top = '0px';
  popup.style.left = '0px';
  popup.style.fontWeight = '500';
  popup.style.fontSize = '0.8rem';
  popup.style.opacity = 0;
  popup.style.visibility = 'visible';
  popup.style.borderRadius = '8px';
  // popup.style.boxShadow = '0 0 6px rgba(0, 0, 0, 0.5)';
  popupArrow.style.borderStyle = 'solid';
  popupArrow.style.borderWidth = '12px 10px 0 10px';
  popupArrow.style.borderColor = '#eee transparent transparent transparent';
  popupArrow.style.content = '';
  popupArrow.style.position = 'absolute';
  popupArrow.style.bottom = '-8px';
  popupArrow.style.left = '50%';
  popupArrow.style.transform = 'translateX(-50%)';
  popupArrow.style.width = '0px';
  popupArrow.style.height = '0px';
  popupArrow.style.zIndex = -1;
  popupText.innerText = innerText;
  popup.appendChild(popupText);
  popup.appendChild(popupArrow);
  body.appendChild(popup);

  return popup;
}
