import { createSlice } from '@reduxjs/toolkit';
import { getStrage } from '../helpers/strage';

const strage = getStrage('setting');
const defaultState_theme = {
  themeId: 'a',
  themeNameId: 'summer-canvas',
  themeName: 'Summer Canvas',
};
const initialState_theme = strage.hasOwnProperty('theme')
  ? strage.theme
  : defaultState_theme;

const defaultState_language = 'ja';
const initialState_language = strage.hasOwnProperty('language')
  ? strage.language
  : defaultState_language;

const defaultState_hexCase = 'upperCase';
const initialState_hexCase = strage.hasOwnProperty('hexCase')
  ? strage.hexCase
  : defaultState_hexCase;
// const defaultState = {
//   theme: defaultState_theme,
//   language: defaultState_language,
//   hexCase: defaultState_hexCase,
// };
const initialState = {
  theme: initialState_theme,
  language: initialState_language,
  hexCase: initialState_hexCase,
};
const themeConfig = {
  a: {
    theme: {
      themeId: 'a',
      themeNameId: 'summer-canvas',
      themeName: 'Summer Canvas',
    },
  },
  b: {
    theme: {
      themeId: 'b',
      themeNameId: 'dragon-cosmo',
      themeName: 'Dragon Cosmo',
    },
  },
  c: {
    theme: {
      themeId: 'c',
      themeNameId: 'red-park',
      themeName: 'Red Park',
    },
  },
  d: {
    theme: {
      themeId: 'd',
      themeNameId: 'vapor-sound',
      themeName: 'Vapor Sound',
    },
  },
};

export const preferenceSlice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    changeTheme: (state, action) => {
      state.theme = themeConfig[action.payload]['theme'];
    },
    changeHexCase: (state, action) => {
      state.hexCase = action.payload.hexCase;
    },
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
    initPreference: (state) => {
      state.theme = defaultState_theme;
      state.language = defaultState_language;
      state.hexCase = defaultState_hexCase;
    },
  },
});

export const { changeTheme, changeHexCase, changeLanguage, initPreference } =
  preferenceSlice.actions;

export default preferenceSlice.reducer;
