import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeColorTipContentsDisplayState } from '../../redux/colorTipContentsSlice';
import { setStrage } from '../../helpers/strage';
import Checkbox from '../../components/Checkbox';

function ScaleTipContenytsCks() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const activeCkIndex = useSelector(
    (state) => state.colorTipContents.activeCkIndex
  );
  const activeCkHex = useSelector(
    (state) => state.colorTipContents.activeCkHex
  );
  const activeCkRgb = useSelector(
    (state) => state.colorTipContents.activeCkRgb
  );

  const bindChange = (e) => {
    dispatch(
      changeColorTipContentsDisplayState({
        activeCk: e.target.dataset.activeCk,
        vid: e.target.dataset.vid,
      })
    );
  };

  setStrage('setting', {
    colorTipContents: {
      activeCkIndex,
      activeCkHex,
      activeCkRgb,
    },
  });

  return (
    <div>
      <Checkbox
        onChange={bindChange.bind(this)}
        title={locale.option_section_color_tip_contents_input_label_index}
        vid="index"
        checked={activeCkIndex}
      />
      <Checkbox
        onChange={bindChange.bind(this)}
        title={locale.option_section_color_tip_contents_input_label_hex}
        vid="hex"
        checked={activeCkHex}
      />
      <Checkbox
        onChange={bindChange.bind(this)}
        title={locale.option_section_color_tip_contents_input_label_rgb}
        vid="rgb"
        checked={activeCkRgb}
      />
    </div>
  );
}

export default ScaleTipContenytsCks;
