import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  showModal,
  showBackdrop,
  hideBackdrop,
  hideSettingCanvas,
} from '../redux/layoutsSlice';
import { changeModalContent } from '../redux/modalContentSlice';
import { isHex } from '../helpers/colorHelpers';

function SettingCanvasState() {
  const dispatch = useDispatch();
  const words = useSelector((state) => state.words.value);
  const lang = useSelector((state) => state.preference.language);
  const locale = words[lang];
  const themeName = useSelector((state) => state.preference.theme.themeName);
  const colorHex = useSelector((state) => state.colorHex.value);

  const handleOpenFavLink = () => {
    dispatch(changeModalContent({ value: 'favs' }));
    dispatch(hideSettingCanvas());
    dispatch(hideBackdrop());

    setTimeout(() => {
      dispatch(showBackdrop());
      dispatch(showModal());
    }, 300);
  };

  return (
    <div>
      <div className="setting-canvas-state-item">
        <span className="setting-canvas-state-item__key">
          {locale.setting_canvas_state_sec_theme_title} :
        </span>
        <span className="setting-canvas-state-item__val">{themeName}</span>
      </div>
      <div className="setting-canvas-state-item">
        <span className="setting-canvas-state-item__key">
          {locale.setting_canvas_state_sec_color_title} :
        </span>
        <span className="setting-canvas-state-item__val">
          <span
            className={
              !isHex(colorHex) ? 'text-strike-and-disabled-in-canvas' : ''
            }
          >
            #{colorHex}
          </span>
        </span>
      </div>
      <div className="setting-canvas-state-item">
        <span className="setting-canvas-state-item__key">
          {locale.setting_canvas_state_sec_fav_color_title} :
        </span>
        <span className="setting-canvas-state-item__val">
          <button
            onClick={() => {
              handleOpenFavLink();
            }}
            className="app-btn-link"
          >
            {locale.setting_canvas_state_sec_fav_color_link}
          </button>
        </span>
      </div>
    </div>
  );
}

export default SettingCanvasState;
