import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  100: 80,
  200: 60,
  300: 40,
  400: 20,
  500: 1,
  600: 20,
  700: 40,
  800: 60,
  900: 80,
};

export const bsDeviationPercentsSlice = createSlice({
  name: 'bsDeviationPercents',
  initialState,
  reducers: {
    changeScaleTipBsDeviationPercent: (state, action) => {
      let v = action.payload.value;
      let index = action.payload.index;
      if (Number(v) <= 100 && !isNaN(Number(v))) {
        state[index] = Number(v);
      }
    },
  },
});

export const { changeScaleTipBsDeviationPercent } =
  bsDeviationPercentsSlice.actions;
export default bsDeviationPercentsSlice.reducer;
