import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideBackdrop, hideSettingCanvas } from '../redux/layoutsSlice';

function Backdrop() {
  const dispatch = useDispatch();
  const isShownBackdrop = useSelector((state) => state.layouts.isShownBackdrop);

  const handleClick = () => {
    dispatch(hideBackdrop());
    dispatch(hideSettingCanvas());
  };

  useEffect(() => {
    // body要素のスクロールを禁止する
    if (isShownBackdrop) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = 'visible';
    }
  });

  return (
    <div
      className={`app-backdrop app-backdrop--type_offcanvas ${
        isShownBackdrop ? 'show' : ''
      }`}
      onClick={() => {
        handleClick();
      }}
    ></div>
  );
}

// const mapDispatchToProps = (dispatch) => ({
//   hideBackdrop: () => dispatch(hideBackdrop()),
//   hideSettingCanvas: () => dispatch(hideSettingCanvas()),
//   hideModal: () => dispatch(hideModal()),
// });

export default Backdrop;
